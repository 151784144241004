import { Link, graphql } from 'gatsby'
import React from 'react'

import { MarkdownComponent, SharePost } from '@/components'
import SEO from '@/components/seo'
import { dateParser } from '@/helpers'
import { ArrowBackIconComponent } from '@/icons'
import { Label, Heading, Image, Subheading } from '@/uikit'

interface Props {
  data: GatsbyTypes.PostTemplateQuery
}

const BlogPostPage: React.FC<Props> = ({ data }) => {
  const { blogPost } = data || {}
  const { id, frontmatter } = data?.blogPost || {}

  return (
    <>
      <SEO title={blogPost.frontmatter.meta_tags.title} meta={blogPost.frontmatter.meta_tags} />
      <div className="flex flex-col items-center justify-center w-full pt-13 md:pt-15">
        <div className="max-w-610 w-full mb-40">
          <div className="px-7.5 md:px-0">
            <Link to="/blog" className="group flex items-center h-10">
              <ArrowBackIconComponent className="block md:hidden dark:text-text-dm md:w-10 md:h-10 w-6 h-6 md:mt-0 transform group-hover:-translate-x-2 duration-500 print:hidden" />
              <Label>
                <span className="ml-5 md:ml-0 inline-block print:mt-10 print:ml-0">Blog</span>
              </Label>
            </Link>
            <div className="mb-7.5 relative">
              <Link
                to="/blog"
                className="absolute -left-15 top-4 hidden md:block transform hover:-translate-x-2 duration-500"
              >
                <ArrowBackIconComponent />
              </Link>
              <div className="mb-7.5">{frontmatter?.title && <Heading>{frontmatter.title}</Heading>}</div>
            </div>
            {frontmatter && (
              <p className="font-inter font-light text-base leading-small text-black-lighter text-opacity-70 mb-7.5 dark:text-text-dm dark:text-opacity-70">
                {dateParser(frontmatter.date_of_publication)} |{' '}
                {frontmatter.post_categories &&
                  frontmatter.post_categories.map(
                    (item: string) => `${item.charAt(0).toUpperCase() + item.slice(1)} `
                  )}{' '}
                | {frontmatter.author}
              </p>
            )}
          </div>

          <div>
            {frontmatter?.bp_image_light && (
              <div className="px-7.5 md:px-0 flex justify-center bg-noise md:rounded-2xl mb-4.3">
                <Image src={frontmatter?.bp_image_light} alt="hero" className="ani ani-scale" />
              </div>
            )}
            {blogPost?.rawMarkdownBody && (
              <MarkdownComponent className="dark:text-white px-7.5 md:px-0">
                {blogPost.rawMarkdownBody}
              </MarkdownComponent>
            )}
          </div>
          {frontmatter && <SharePost title={frontmatter.title} excerpt={frontmatter.excerpt} />}
        </div>
        <div className="max-w-1250 w-full bg-white dark:bg-body-bg1-dm pt-20 md:pt-0 md:bg-transparent md:dark:bg-transparent mb-20 md:mb-230">
          <div className="ml-7.5 md:ml-77">
            <div className="mb-2.5">
              <Label>Read More</Label>
            </div>
            <div className="mb-15">
              <Subheading>Related Posts</Subheading>
            </div>
          </div>
          <div className="bg-white dark:bg-body-bg1-dm rounded-2xl items-start flex flex-col md:flex-row  justify-center flex-wrap md:justify-between px-7.5 md:p-20 md:pr-0 xl:pr-160  ">
            {data.blogList.edges &&
              data.blogList.edges
                .filter((post) => post.node.id !== id)
                .map((post) => {
                  return (
                    <Link
                      to={`/blog/${post.node.frontmatter.url}`}
                      className="flex flex-col w-full md:flex-row md:max-w-450 md:mb-7.5 mb-15 group"
                      key={post.node.id}
                    >
                      <div className="mb-2.5 md:mb-0 md:h-106 mb-5 bg-noise flex items-center md:items-end rounded-semiXl">
                        <div className="h-48 w-72 md:h-24 md:w-36 rounded-semiXl bg-body-bg2 dark:bg-body-bg2-dm overflow-hidden">
                          {post?.node?.frontmatter?.bp_image_light && (
                            <Image
                              src={post.node.frontmatter.bp_image_light}
                              alt="related_image"
                              className="block rounded-semiXl object-cover h-full w-full transition-transform duration-500 hover:scale-105 transform"
                            />
                          )}
                        </div>
                      </div>
                      <div className="md:ml-7.5">
                        <p className="font-inter font-light text-base leading-small text-black-lighter text-opacity-70 dark:text-text-dm dark:text-opacity-70 mb-2.5">
                          {dateParser(post.node.frontmatter.date_of_publication)} |{' '}
                          {post.node.frontmatter.post_categories &&
                            post.node.frontmatter.post_categories.map(
                              (item: string) => `${item.charAt(0).toUpperCase() + item.slice(1)} `
                            )}{' '}
                          {post.node.frontmatter.author ? ` | ${post.node.frontmatter.author}` : ''}
                        </p>
                        <p className="md:max-w-290 w-full font-inte font-semibold text-text dark:text-gray-darker text-lg leading-medium dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
                          {post.node.frontmatter.excerpt}
                        </p>
                      </div>
                    </Link>
                  )
                })}
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPostPage
export const postQuery = graphql`
  query PostTemplateQuery($id: String!) {
    blogPost: markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      frontmatter {
        title
        excerpt
        excerpt
        author
        date_of_publication
        post_categories
        url
        meta_tags {
          title
          description
          og_image {
            childImageSharp {
              gatsbyImageData(width: 1500, formats: [PNG], placeholder: BLURRED, layout: FIXED)
            }
            relativePath
          }
          nofollow
          noindex
        }
        bp_image_light {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 610, formats: [WEBP])
          }
          extension
          publicURL
        }
      }
    }
    blogList: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/.*/" } }
      sort: { fields: frontmatter___date_of_publication, order: DESC }
      limit: 7
    ) {
      edges {
        node {
          id
          frontmatter {
            url
            title
            date_of_publication
            post_categories
            excerpt
            author
            bp_image_light {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 260, formats: [WEBP])
              }
              extension
              publicURL
            }
          }
        }
      }
    }
    postCategories: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/data/post-categories.md/" } }) {
      edges {
        node {
          id
          frontmatter {
            list_of_categories
          }
        }
      }
    }
  }
`
